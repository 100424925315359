import FracTable from 'src/components/05.table';
import { ColumnsType } from 'antd/es/table';
import CommonFilter, {
  CommonFilterParams,
  DEFAULT_FILTER_PARAMS,
} from 'src/components/21.common-filter';
import './styles.scss';
import { Form, Radio, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PAGE_SIZE_OPTIONS } from 'src/constants';
import { fetchPositionList } from 'src/store/actions/future-records';
import dayjs from 'dayjs';
import FracTooltip from 'src/components/19.tooltip';
import { ellipseAddress } from 'src/helpers';
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/formatters';
import { formatRoundFloorDisplay } from 'src/helpers/formatNumber';
import { PositionInfo } from 'src/services/params-type/future-records';

const Positions = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isActivePosition, setIsActivePosition] = useState(true);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [filterParams, setFilterParams] = useState<CommonFilterParams>(
    DEFAULT_FILTER_PARAMS
  );

  const positionData = useSelector(
    (state: any) => state.futureRecords.positionData
  );

  useEffect(() => {
    setCurPage(1);
  }, [filterParams, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchPositionList(
        {
          page: curPage,
          limit: pageSize,
          // orderBy: 'time',
          // direction: 'DESC',
          isActive: isActivePosition,
          from: (
            dayjs(filterParams.startTime).startOf('day').unix() * 1000
          ).toString(),
          to: (
            dayjs(filterParams.endTime).endOf('day').unix() * 1000
          ).toString(),
          walletAddress: filterParams.searchText?.length
            ? filterParams.searchText
            : undefined,
        },
        () => setIsLoading(false)
      )
    );
  }, [filterParams, pageSize, curPage, dispatch, isActivePosition]);

  const columns: ColumnsType<PositionInfo> = [
    {
      title: 'Time',
      render: (record: PositionInfo) => (
        <Space size={4} direction="vertical">
          <span className="text-nowrap">
            {dayjs(record?.createdAt).format(DATE_FORMAT)}
          </span>
          <span className="text-nowrap gray-text">
            {dayjs(record?.createdAt).format(TIME_FORMAT)}
          </span>
        </Space>
      ),
    },
    {
      title: 'Owner wallet address',
      render: (record: PositionInfo) => (
        <FracTooltip title={record?.userWallet}>
          <span>{ellipseAddress(record?.userWallet || '', 6, 4)}</span>
        </FracTooltip>
      ),
    },
    {
      title: 'Symbol',
      render: (record: PositionInfo) => (
        <Space size={4} direction="vertical">
          <span className="text-nowrap">{record?.symbol}</span>
          <span className="text-nowrap gray-text">Perpetual</span>
        </Space>
      ),
    },
    {
      title: 'Side',
      render: (record: PositionInfo) => (
        <span
          className={
            (record?.side || '').toLowerCase() === 'short'
              ? 'red-text'
              : 'green-text'
          }
        >
          {record?.side}
        </span>
      ),
    },
    {
      title: 'Size',
      render: (record: PositionInfo) => (
        <span>{formatRoundFloorDisplay(record?.size)}</span>
      ),
    },
    {
      title: 'Entry Price',
      render: (record: PositionInfo) => (
        <span>{formatRoundFloorDisplay(record?.entryPrice)}</span>
      ),
    },
    {
      title: 'Liq.Price',
      render: (record: PositionInfo) => (
        <span>{formatRoundFloorDisplay(record?.liquidationPrice)}</span>
      ),
    },
    {
      title: 'Leverage',
      render: (record: PositionInfo) => (
        <span>
          {formatRoundFloorDisplay(record?.leverage, 3)}
          {record?.leverage && 'x'}
        </span>
      ),
    },
    {
      title: 'Position Margin',
      render: (record: PositionInfo) => (
        <span>{formatRoundFloorDisplay(record?.positionMargin)}</span>
      ),
    },
    {
      title: 'Maintenance Margin',
      render: (record: PositionInfo) => (
        <span>{formatRoundFloorDisplay(record?.maintainMargin)}</span>
      ),
    },
    {
      title: 'Mark Price',
      render: (record: PositionInfo) => (
        <span>{formatRoundFloorDisplay(record?.markPrice)}</span>
      ),
    },
  ];

  return (
    <div className="positions">
      <h1 className="common-title">Positions</h1>
      <Radio.Group
        value={isActivePosition}
        onChange={(e) => setIsActivePosition(e.target.value)}
      >
        <Radio value={true}>Active position</Radio>
        <Radio value={false}>Position history</Radio>
      </Radio.Group>
      <CommonFilter
        form={form}
        // searchBy="symbol"
        isLoading={isLoading}
        // placeholder="Search by symbol"
        filterParams={filterParams}
        setFilterParams={setFilterParams}
      />
      <div className="common-wrapper">
        <FracTable
          showTotal
          columns={columns}
          isLoading={isLoading}
          totalText="Total Potitions"
          dataSource={positionData.data || []}
          pagination={{
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            pageSize: pageSize,
            showSizeChanger: true,
            total: positionData?.metadata?.total,
            current: curPage,
            onShowSizeChange(current, size) {
              setPageSize(size);
            },
            onChange(page, pageSize) {
              setCurPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Positions;
